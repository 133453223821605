import React from 'react';
import logo from './logo.svg';
import './App.css';
import { useAuth } from 'react-oidc-context';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ObjectUpload } from './ObjectUpload';

function App() {
  const auth = useAuth();
  let content = <span/>
 
  switch (auth.activeNavigator) {
    case "signinSilent":
      content = <span>Signing you in...</span>; 
      break;
    case "signinRedirect":
      content = <span>Signing you out...</span>;
      break;
  }
  if (auth.isLoading) {
    content = <span>Loading...</span>;
  }
  if (auth.error) {
    content = <span>Oops... {auth.error.message}</span>;
  }
  if (auth.isAuthenticated) {
    content = (
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Edit <code>src/App.tsx</code> and save to reload.
          </p>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a>

        </header>
    );
  } else {
    content = <button onClick={() => void auth.signinRedirect()}>Log in</button>;  
  }
  const notify = () => toast("Wow so easy!");

  return (
    <div className="App">
      {content}
      <div>
        <button onClick={notify}>Notify!</button>
        <ToastContainer /> 
      </div>
      <ObjectUpload />
    </div>
  );
}

export default App;
