import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { AuthProvider } from 'react-oidc-context';
import App from './App';
import reportWebVitals from './reportWebVitals';

const oidcConfig = {
  authority: 'https://qa-oauth.mintel.com',
  client_id: '2EaMoyp0duUuRIOX5d03uSuwblkGeYNhoIVNOCoK',
  redirect_uri: `${window.location.origin}`,
  response_type: 'code',
  post_logout_redirection_uri: `${window.location.origin}`,
  automaticSilentRenew: true,
  loadUserInfo: true
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthProvider { ...oidcConfig }>
      <App />
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
