import React, {useState} from 'react';
import { useAuth } from "react-oidc-context";

export const ApiUrl = 'https://api.object-upload-726170.everest-qa.aws.mintel.com/initiateObjectUpload';

export const ObjectUpload = () => {
    const [apiKey, setApiKey] = useState("");
    const [source, setSource] = useState("");
    const [feed, setFeed] = useState("");
    const [filename, setFilename] = useState("");
    const [selectedFile, setSelectedFile] = useState<File>(); 
    const [signedPutUrl, setSignedPutUrl] = useState("");
    const auth = useAuth();
    const onApiKeyChange = (e: React.FormEvent<HTMLInputElement>) => {
        const value = (e.target as HTMLInputElement).value;
        setApiKey(value ? value : '');
    };

    const onSourceChange = (e: React.FormEvent<HTMLInputElement>) => {
        const value = (e.target as HTMLInputElement).value;
        setSource(value ? value : '');
    }
    
    const onFeedChange = (e: React.FormEvent<HTMLInputElement>) => {
        const value = (e.target as HTMLInputElement).value;
        setFeed(value ? value : '');
    }

    const onFileSelected = (e: React.FormEvent<HTMLInputElement>) => {
        const files = (e.target as HTMLInputElement).files;
        if (!files) {
            setSelectedFile(undefined);
            setFilename("");
        } else {
            setSelectedFile(files[0]);
            setFilename(files[0].name);
        }
    };

    const finishTheJob = (sp: string) => {
        console.log("ccc");
        console.log(sp)
        console.log(signedPutUrl);

        if (!selectedFile) return;
        fetch(sp, {
            method: "PUT", 
            body: selectedFile,
            headers: {
                "Content-Type": selectedFile.type,
            },
        }).then(res => {
            console.dir(res);
        });
    }

    const uploadFile = (e: React.FormEvent<HTMLButtonElement>) => {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("X-Api-Key", apiKey);
        const token = auth.user?.access_token
        if (token) {
          headers.append("Authorization", `Bearer: ${token}`);
        }

        interface RequestData {
            filename: string;
            source: string;
            feed?: string;
        }

        const data: RequestData = {
            filename: filename,
            source: source,
        }

        if (feed) {
            data.feed = feed;
        }

        // First get the signed pur 
        fetch(ApiUrl, {
            method: 'POST',
            mode: 'cors',
            headers: headers,
            body: JSON.stringify(data),
        }).then(
            res => res.json()
        ).then(
            text => {
                console.log(text);
                console.log('a');
                setSignedPutUrl(text);
                return text;
            }
        ).then(
            url => {
                console.log(url);
                console.log('bbb');
                console.log(signedPutUrl);
                finishTheJob(url);
            }
        );
    };

    // File content to be displayed after 
    // file upload is complete 
    const fileData = () => { 
      if (selectedFile) { 
        return ( 
          <div> 
            <h2>File Details:</h2> 
            <p>File Name: {selectedFile.name}</p> 
            <p>File Type: {selectedFile.type}</p> 
            <p>File Size: {selectedFile.size}</p>
            <p> 
              Last Modified:{" "} 
              {selectedFile.lastModified.toLocaleString()} 
            </p> 
          </div> 
        ); 
      } else { 
        return ( 
          <div> 
            <br /> 
            <h4>Choose before Pressing the Upload button</h4> 
          </div> 
        ); 
      } 
    }; 

    return (
      <div> 
        <input type="text" name="ApiKey" defaultValue="Enter your API Key" required={true} onChange={onApiKeyChange} />
        <input type="text" name="Source" defaultValue="Enter your source" required={true} onChange={onSourceChange} />
        <input type="text" name="Feed" required={false} onChange={onFeedChange} />
        <input type="file" name="File" onChange={onFileSelected} /> 
        <button onClick={uploadFile}>
          Upload file
        </button>
        {fileData()} 
      </div> 
    );
};